import React from "react"
import Back from "../common/back/Back"
import PriceCard from "./PriceCard"
import "./price.css"
import Faq from "./Faq"

const Pricing = () => {
  return (
    <>
      <Back title='Choose The Right Plan' />
      <section className='price padding '>
        

        <div className="price container grid">
        <PriceCard />
        </div>
        
          
       
      </section>
      {/* <Faq /> */}
    </>
  )
}

export default Pricing
