import React from "react"
import Heading from "../../common/heading/Heading"
import { Link } from "react-router-dom"
import "./Hero.css"
import logo from './image.png'

const Hero = () => {
  return (
    <>
      <div className='hero'>
        <div className="her"></div>
        <div className="her-img">
          {/* <img src={logo} alt="" className="img-he"/> */}
        </div>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO ATYA Edu' title='Best Online Education Expertise' />
            <h2 style={{marginTop:'-1rem' , marginBottom : '1rem' , color: '#1271FF'}}>Empowering minds - Enriching lives</h2>
            <p>We empower students through hands-on workshops, live sessions, and hackathons. Our programs focus 
              on building practical skills and boosting intelligence. Join us to learn, innovate, and grow!</p>
            <div className=''>
              <a href="#price">
              <button className='btn' >
                
                GET STARTED NOW 
             </button>
              </a>
               
              <Link to='/courses'><button className="btn-n" style={{color:'rgb(19, 113, 255)'}}>
                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
               
            </div>
             
          </div> 
        </div>
      </div>
      <div className='margin'></div>
      {/* <button className="btn">hello</button> */}
    </>
  )
}

export default Hero
