import React, { useRef } from "react"
import emailjs from 'emailjs-com'
import Back from "../common/back/Back"
import "./contact.css"
import contact from './contact.svg'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bifrpdh', 'template_5b3c4rw', form.current, 'QlxFzpco7bUoMyMUa')
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully!");
          form.current.reset(); 
      }, (error) => {
          console.log(error.text);
          alert("Failed to send the message. Please try again.");
      });
  };

  return (
    <>
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <img src={contact} alt="" className="svg" />
          </div>
          <div className='right row'>
            <h1>Contact us</h1>
            <p>Have questions or need more information? Feel free to reach out to us. We're here to help!</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p>atyaeducation@gmail.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p>+91 6300292491</p>
              </div>
            </div>

            <form ref={form} onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' name='from_name' placeholder='Your Name' required />
                <input type='email' name='reply_to' placeholder='Your Email' required />
              </div>
              <input type='text' name='subject' placeholder='Subject' required />
              <textarea name='message' cols='30' rows='10' placeholder='Create a message here...' required></textarea>
              <button type='submit' className='primary-btn btn-c'>SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact;
