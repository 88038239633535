import React, { useState } from "react";
import './header.css';
import { IoMdMenu, IoMdClose } from "react-icons/io";  // Import close icon
import { Link } from 'react-router-dom';  // Assuming you're using react-router for navigation
import logo from './image.png';
import { AiFillHome } from "react-icons/ai";
import { IoDesktop } from "react-icons/io5";
import { FaAddressBook } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoChatbox } from "react-icons/io5";

const Head = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to control the menu

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <img src={logo} className="alan" alt="" />
            <h1>ATYA Edu</h1>
            <h4>ATYA Edu</h4>
          </div>

          <div className='social'>
            <a href="http://" target="_blank" rel="noopener noreferrer"> <i className='fab fa-facebook-f icon'></i></a>
            <a href="https://www.instagram.com/atya_education/profilecard/?igsh=Z2E3YTE0cmlkYjlt" target="_blank" rel="noopener noreferrer"><i className='fab fa-instagram icon'></i></a>
             <a href="http://" target="_blank" rel="noopener noreferrer"><i className='fab fa-twitter icon'></i></a>
             <a href="https://www.linkedin.com/company/atya-edu" target="_blank" rel="noopener noreferrer"> <i className='fab fa-linkedin icon'></i></a>
            
          </div>

          <IoMdMenu className="menu" onClick={toggleMenu} /> {/* Click to toggle menu */}
        </div>
      </section>

      {/* Sliding menu */}
      <nav className={`side-menu ${menuOpen ? 'open' : ''}`}> {/* Conditional class for open state */}
        <IoMdClose className="close-menu" onClick={toggleMenu}/> {/* Close button */}
        <ul>
          <li>
            
            <Link to='/' onClick={toggleMenu}> <AiFillHome className="icn"/>Home</Link> {/* Close menu on link click */}
          </li>
          <li>
            <Link to='/courses' onClick={toggleMenu}><IoDesktop className="icn"/> All Courses</Link>
          </li>
          <li>
            <Link to='/about' onClick={toggleMenu}><FaAddressBook className="icn"/> About</Link>
          </li>
          {/* <li>
            <Link to='/team' onClick={toggleMenu}><BsFillPeopleFill className="icn"/> Team</Link>
          </li> */}
          <li>
            <Link to='/pricing' onClick={toggleMenu}><FaMoneyBillTrendUp className="icn"/> Pricing</Link>
          </li>
          <li>
            <Link to='/contact' onClick={toggleMenu}><IoChatbox className="icn"/> Contact</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Head;
