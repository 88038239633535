import React from "react"
import { price } from "../../dummydata"
import './price.css'

const PriceCard = () => {
  return (
    <>
      {price.map((val) => (
        <div className='items shadow'>
          <h4 style={{textAlign : 'center' , fontSize : '1.5rem'}}>{val.name}</h4>
           
          <h1>
          
             
            <div className="ce">
            {val.price}

            </div>
            <span style={{fontSize : '0.7rem' , marginRight : '0.2rem' , marginTop : '1rem' , position : 'absolute'}}>starting from</span>
            
            
            {/* <p>per person</p> */}
          </h1>
          <p style={{marginTop : '3rem'}}>{val.desc}</p>
          <button className='outline-btn enroll'>GET STARTED</button>
        </div>
      ))}
    </>
  )
}

export default PriceCard
