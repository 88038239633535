import React from "react"
import { blog } from "../../../dummydata"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Stay informed and never miss out on our latest courses and updates!</h1>
            {/* <span>Far far away, behind the word mountains</span>/\ */}
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            <i className='sub'>Submit</i>
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo fot'>
            <h1>ATYA Edu</h1>
            <h3>ATYA Edu</h3>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>We empower students through hands-on workshops, live sessions, and hackathons. 
              Our programs focus on building practical skills and boosting intelligence. Join us to learn, innovate, and grow!</p>
            <div className="soc">
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>

            </div>
            
          </div>
          <div className='box linkk kk'>
            <h3>Explore</h3>
            <ul>
              <li>About Us</li>
              <li>Services</li>
              <li>Courses</li>
              <li>Blog</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className='box linkk'>
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div>
          {/* <div className='box recent'>
            <h3>Recent Post</h3>
            {blog.slice(0, 3).map((val) => (
              <div className='items flexSB'>
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
                <div className='text'>
                  <span>
                    <i className='fa fa-calendar-alt'></i>
                    <label htmlFor=''>{val.date}</label>
                  </span>
                  <span>
                    <i className='fa fa-user'></i>
                    <label htmlFor=''>{val.type}</label>
                  </span>
                  <h4>{val.title.slice(0, 40)}...</h4>
                </div>
              </div>
            ))}
          </div> */}
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
            
              <li>
                <i className='fa fa-phone-alt'></i>
                +91 6300292491
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                atyaeducation@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved 
        </p>
      </div>
    </>
  )
}

export default Footer
